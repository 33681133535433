import React from "react"
import styled, { css } from "styled-components"
import FB from "../../images/FB_v1.inline.svg"
import IG from "../../images/IG_v1.inline.svg"
import Info from "../../images/Info-1_v2.inline.svg"
import HoverInfo from "../../images/Info-2_v1.inline.svg"
import LinkedIn from "../../images/LinkedIn_v1.inline.svg"
import Pointer from "../../images/Pointer_v1.inline.svg"
import RightArrow from "../../images/Right-Arrow-Button_v2.inline.svg"
import Tick from "../../images/Tick_v2.inline.svg"
import Twitter from "../../images/Twitter_v1.inline.svg"
import Icon from "./IconBase"

const TickIcon = styled(Tick)`
  margin-top: 5px;
  margin-right: 15px;
  min-width: 3rem;
  height: 2.5rem;

  @media screen and (max-width: 1024px) {
    min-width: 26pt;
    height: 26pt;
  }

  @media screen and (max-width: 767px) {
    min-width: 24pt;
    height: 24pt;
  }
`

const RightArrowIcon = styled(RightArrow)`
  margin-left: 10px;
  width: 3rem;
  height: 2rem;

  @media screen and (max-width: 1024px) {
    width: 33pt;
    height: 26pt;
  }

  @media screen and (max-width: 767px) {
    width: 31pt;
    height: 24pt;
  }
`

const PointerIcon = styled(Pointer)`
  margin-top: 5px;
  margin-right: 20px;
  min-width: 1rem;
  height: 1rem;

  @media screen and (max-width: 1024px) {
    width: 20pt;
    height: 20pt;
  }

  @media screen and (max-width: 767px) {
    width: 20pt;
    height: 20pt;
  }
`

const InfoIcon = styled(Info)`
  margin-top: 5px;
  margin-right: 1rem;
  min-width: 2rem;
  max-width: 2rem;
  min-height: 2rem;
  max-height: 2rem;

  @media screen and (max-width: 1024px) {
    width: 26pt;
    height: 26pt;
  }

  @media screen and (max-width: 767px) {
    width: 24pt;
    height: 24pt;
  }
`

// min-width: 2rem;
// max-width: 2rem;
// min-height: 2rem;
// max-height: 2rem;

const HoverInfoIcon = styled(HoverInfo)`
  margin-top: 5px;
  margin-right: auto;
  min-width: 2rem;
  max-width: 2rem;
  min-height: 2rem;
  max-height: 2rem;

  @media screen and (max-width: 1024px) {
    width: 26pt;
    height: 26pt;
  }

  @media screen and (max-width: 767px) {
    width: 24pt;
    height: 24pt;
  }
`

const SocialMediaIconStyle = css`
  width: 2rem;
  height: 2rem;

  @media screen and (max-width: 1024px) {
    width: 24pt;
    height: 24pt;
  }

  @media screen and (max-width: 767px) {
    width: 24pt;
    height: 24pt;
  }
`

const FBIcon = styled(FB)`
  ${SocialMediaIconStyle}

  :hover {
    #Icon_awesome-facebook-square {
      fill: #1b5998 !important;
      transition: all 0.5s;
    }
  }
`
const LinkedInIcon = styled(LinkedIn)`
  ${SocialMediaIconStyle}

  :hover {
    #Icon_awesome-linkedin {
      fill: #1b5998 !important;
      transition: all 0.5s;
    }
  }
`
const IGIcon = styled(IG)`
  ${SocialMediaIconStyle}

  :hover {
    #Icon_awesome-instagram {
      fill: #1b5998 !important;
      transition: all 0.5s;
    }
  }
`
const TwitterIcon = styled(Twitter)`
  ${SocialMediaIconStyle}

  :hover {
    #Icon_awesome-twitter {
      fill: #1b5998 !important;
      transition: all 0.5s;
    }
  }
`

const ArrowSvg = styled(Icon)`
  flex-shrink: 0;
  margin: 0 0.25rem;
  transform: scale(1);
  margin-right: -0.25rem;
`

const CheckSvg = styled(Icon)`
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  color: rgb(94, 128, 230);
`

const Arrow = ({ className }) => (
  <ArrowSvg className={className}>
    <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
  </ArrowSvg>
)

const Check = ({ className }) => (
  <CheckSvg className={className}>
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
  </CheckSvg>
)

export {
  Arrow,
  Check,
  TickIcon,
  RightArrowIcon,
  PointerIcon,
  InfoIcon,
  HoverInfoIcon,
  FBIcon,
  LinkedInIcon,
  IGIcon,
  TwitterIcon,
}
