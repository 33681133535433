import styled from "styled-components"

const PriceGridContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  margin: 16px 0px;

  @media screen and (min-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 16px 5%;
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
    margin: 16px 5%;
  }
`

const PriceGridContainerItem = styled.div`
  border-radius: 8px;
`

const PriceGridContainerItemPromoBanner = styled.div`
  display: flex;
  justify-content: center;
  color: #5e80e6;
  background: #e8ebf7;
  border-radius: 8px 8px 0px 0px;
  height: 100%;
`

const PriceGridContainerItemContent = styled.div`
  display: flex;
  padding: 30px;
  text-align: left;
  align-items: flex-start;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16), 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
`

const PriceGridContainerItemChip = styled.div`
  color: rgba(0, 0, 0, 0.87);
  border: none;
  cursor: default;
  height: 32px;
  display: inline-flex;
  outline: 0;
  padding: 0;
  font-size: 0.8125rem;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: Poppins;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #e2e8f0;
`

const PriceGridContainerItemChipLabel = styled.div`
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  font-weight: 500;
`

const PriceGridContainerItemHeaderContainer = styled.div`
  margin-bottom: 24px;
`

const PriceGridContainerItemContentContainer = styled.div`
  height: 152px;
  overflow: auto;
  margin-top: auto;
`

const PriceGridContainerItemFeatureListContainer = styled.ul`
height: 256px;
    overflow: auto;
    padding-top: 0;
    padding-bottom: 8px;
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
}
`

const PriceGridContainerItemFeatureListItem = styled.li`
  padding: 4px 0px;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
`

const ProductGridContainer = styled.div`
  display: grid;
  margin-top: 6rem;

  @media screen and (min-width: 1025px) {
    display: flex;
    min-width: min(1600px, 75vw);
    grid-gap: 2rem;

    margin: 6rem auto;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: flex;
    max-width: 100%;
    margin: 6rem auto;
  }

  @media screen and (max-width: 767px) {
    grid-gap: 7rem;
  }
`

const ProductGridContainerItem = styled.div`
  background: var(--theme-ui-colors-white, #ffffff);
  border-radius: 8px;

  box-shadow: 5px 2px 4px rgba(46, 41, 51, 0.08);
  padding: 4px;
  background-repeat: no-repeat;
  background-size: 100% 4px;
  border-radius: 30px;

  @media screen and (min-width: 1025px) {
    width: 400px;
    height: 500px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 47%;
  }
`

// background: linear-gradient(
//   90deg,
//   var(--theme-ui-colors-gatsby, #663399) 0%,
//   var(--theme-ui-colors-blue-40, #3fa9f5) 100%
// );

const ProductGridContainerItemContent = styled.div`
  background: var(--theme-ui-colors-white, #ffffff);
  border-radius: inherit;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  text-align: left;
`

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 6rem;
  margin-bottom: 3rem;
  overflow: hidden;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1024px) {
    /*margin-top: 3rem;*/
    gap: 1rem;
  }

  @media screen and (max-width: 767px) {
    gap: 1rem;
    margin-top: 1rem;
  }
`
// margin-top: 2rem;

// const SectionContainer = styled.section`
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-flex-direction: column;
//   -ms-flex-direction: column;
//   flex-direction: column;
// `

// height: 100vh;

// padding-bottom: 3rem;
// margin-bottom: -3rem;

const SectionContainer = styled.section`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  display: flex;

  @media screen and (min-width: 550px) {
    max-width: 90%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 90%;
  }

  @media screen and (max-width: 767px) {
    width: 90%;
    align-items: center;
    flex-direction: column;
    text-align: left;
    line-height: unset;
  }
`

// @media screen and (max-width: 767px) {
//   width: 95%;
//   align-items: center;
//   text-align: center;
// }
// @media screen and (min-width: 768px) and (max-width: 1024px) {
//   width: 85%;
//   align-items: center;
//   text-align: center;
// }

const SectionContentArea = styled.div`
  position: relative;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  display: flex;

  @media screen and (min-width: 550px) {
    max-width: 92vw;
  }

  @media screen and (max-width: 767px) {
    width: 95%;
    align-items: center;
    text-align: left;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 85%;
    align-items: center;
    text-align: center;
  }
`
// @media screen and (min-width: 550px) {
//   width: 92vw;
// }

//max-width: 90rem;
const SectionHeaderArea = styled.header`
  text-align: center;
  margin-bottom: 3rem;

  @media screen and (max-width: 767px) {
    text-align: left;
  }
`

const SectionTitle = styled.h2`
  margin: 0;
  line-height: 1.25;
  color: #232129;
  font-weight: 800;
  font-family: Futura PT, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  position: relative;
  letter-spacing: -0.015em;
  line-height: 1;
  margin: 0;
`
const SectionSubtitle = styled.div`
  margin: 0;
  white-space: pre-wrap;
  margin: 0 auto;
  margin-top: 1.5rem;
  max-width: 40rem;
`
const SectionTable = styled.div`
  display: grid;
  grid-row-gap: 3rem;
  margin: 3rem 0;

  @media screen and (min-width: 1025px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 6rem;
    margin: 6rem 2.5rem;
    padding: 0 2.5rem;
  }
`

const FAQContainer = styled.div`
  display: grid;
  grid-gap: 3rem;
  margin-bottom: 3rem;
  text-align: left;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1025px) {
    width: 80%;
  }
`

const PromoContainer = styled.div`
  background-color: #e9edfc;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
  height: 14rem;
  align-items: center;

  @media screen and (min-width: 1025px) {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`

const ProfileContainer = styled.div`
  display: flex;
  width: inherit;
  justify-content: space-between;
  margin-top: 5%;

  width: 100%;

  grid-gap: 1rem;

  @media screen and (min-width: 768px) {
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const ProfileContainerItem = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-left: 1%;
  margin-right: 1%;
  min-width: 30%;

  @media screen and (max-width: 767px) {
  }
`

const ProfilePicture = styled.div`
  padding: 150px;
  border-radius: 9999px;
  display: inline-flex;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-bottom: 3rem;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 45%;
  }
`

const ProductContainer = styled.div`
  align-self: center;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

// margin: auto;
// width: 100%;
// height: 100%;
const CTAContainer = styled.section`
  position: relative;
  display: flex;

  @media screen and (max-width: 1024px) {
    align-items: center;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }
`

const CTAContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
`

// @media screen and (min-width: 1025px) {
//   height: min(1080px, 45vw);
// }

// @media screen and (min-width: 768px) and (max-width: 1024px) {
//   height: 50vw;
// }

// @media screen and (max-width: 767px) {
//   height: 80vw;
// }

export {
  PriceGridContainer,
  PriceGridContainerItem,
  PriceGridContainerItemPromoBanner,
  PriceGridContainerItemContent,
  PriceGridContainerItemChip,
  PriceGridContainerItemChipLabel,
  PriceGridContainerItemHeaderContainer,
  PriceGridContainerItemContentContainer,
  PriceGridContainerItemFeatureListContainer,
  PriceGridContainerItemFeatureListItem,
  ProductGridContainer,
  ProductGridContainerItem,
  ProductGridContainerItemContent,
  PageContainer,
  CTAContainer,
  CTAContent,
  SectionContainer,
  SectionContentArea,
  SectionHeaderArea,
  SectionTitle,
  SectionSubtitle,
  SectionTable,
  FAQContainer,
  PromoContainer,
  ProfileContainer,
  ProfileContainerItem,
  ProfilePicture,
  ProductContainer,
}
