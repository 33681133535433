import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import LogoIcon from "../../images/logo-brand-og.png"

const LogoWrap = styled.div`
  margin: auto 0;

  @media screen and (max-width: 767px) {
    max-width: 110px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    max-width: 150px;
  }

  }
`

const Logo = () => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "logo-brand-og" }, extension: { eq: "png" }) {
        childImageSharp {
          gatsbyImageData(width: 195, layout: FIXED)
        }
      }
    }
  `)

  // fluid(maxWidth: 175, pngQuality: 100) {
  //   ...GatsbyImageSharpFluid
  // }

  return (
    <LogoWrap as={Link} to="/">
      {/* <GatsbyImage
        image={data.file.childImageSharp.gatsbyImageData}
        alt="logo"
      /> */}
      <img src={LogoIcon} alt="Logo" width={195} style={{ marginBottom: 0 }} />
    </LogoWrap>
  )
}

export default Logo
