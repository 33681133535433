import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"

const NavContainer = styled.div`
  display: none;
  padding: 1vh 1vw;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  a {
    float: none;
    color: #222222;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
`

const NavContainerItem = styled(Link)`
  display: inline-block;
  white-space: nowrap;
  position: relative;
  color: #222222;
  text-decoration: none;
  font-weight: bold;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #0b3253;
    height: 1px;
  }

  :hover {
    color: #0b3253;
    ::after {
      width: 100%;
      transition: all 0.25s ease-in;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 20px 0;

    z-index: 6;
  }
`

const NavDropdownItem = styled.div`
  display: inline-block;
  white-space: nowrap;
  margin: 0 20px;
  position: relative;
  color: #222222;
  text-decoration: none;
  font-weight: bold;

  letter-spacing: 0.25px;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #0b3253;
    height: 1px;
    transition: all 0.15s ease-in;
  }

  :hover {
    ${NavContainer} {
      display: block;
    }
    color: #0b3253;
    ::after {
      width: 100%;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 20px 0;

    z-index: 6;
  }
`

const NavItem = styled(Link)`
  display: inline-block;
  white-space: nowrap;
  margin: 0 20px;
  position: relative;
  color: #222222;
  text-decoration: none;
  font-weight: 500;

  :before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: all 0.5s;
  }

  :after {
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    width: 100%;
    content: ".";
    color: transparent;
    background: #1b5998;
    height: 3px;
    opacity: 0;
    border-radius: 3px;
  }

  :hover {
    font-weight: 600;
    color: #1b5998;

    text-decoration: none;
    ::after {
      opacity: 1;
      transition: all 0.5s;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 7% 5%;
    z-index: 6;
    width: 100%;

    :hover,
    :active {
      color: #466fa5;
    }

    :after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      content: ".";
      color: transparent;
      background: #5e80e617;
      height: 100%;
      opacity: 0;
      border-radius: 0px;
    }
  }
`

const NavItemOutlined = styled(Link)`
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #222222;
  background: #fffff;
  color: #222222;
  font-weight: 500;
  padding: 1.4rem 1.4rem;
  border-radius: 50px;
  letter-spacing: 0.25px;

  :hover {
    color: #1b5998;
    border: 1px solid #1b5998;
    text-decoration: none;
    transition: all 0.5s;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
  }

  @media screen and (max-width: 767px) {
    display: inline-block;
    white-space: nowrap;
    margin: 0 20px;
    position: relative;
    color: #222222;
    text-decoration: none;
    font-weight: 500;
    padding: 7% 5%;
    z-index: 6;
    width: 100%;
    border: none;

    :before {
      display: block;
      content: attr(title);
      font-weight: 600;
      height: 0;
      overflow: hidden;
      visibility: hidden;
      transition: all 0.5s;
    }

    :hover {
      font-weight: 600;
      color: #1b5998;
      border: none;
      text-decoration: none;

      ::after {
        opacity: 1;
        transition: all 0.5s;
      }
    }

    :hover,
    :active {
      color: #466fa5;
    }

    :after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      content: ".";
      color: transparent;
      background: #5e80e617;
      height: 100%;
      opacity: 0;
      border-radius: 0px;
    }
  }
`

const NavSpace = styled.div`
  width: 60px;
  height: 60px;

  @media screen and (min-width: 1025px) {
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const NavbarLinks = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)
  return (
    <>
      {data.site.siteMetadata.menuLinks.map((link, index) => {
        // if (link.name === "Solution") {
        //   return (
        //     <NavDropdownItem key={index}>
        //       {/* <NavItem to="#">{link.name}</NavItem> */}
        //       Solution
        //       <NavContainer>
        //         <NavContainerItem to="/features">Features</NavContainerItem>
        //         <NavContainerItem to="/business">Business</NavContainerItem>
        //       </NavContainer>
        //     </NavDropdownItem>
        //   )
        // } else {
        return (
          <NavItem key={index} to={link.link} title={link.name}>
            {link.name}
          </NavItem>
        )
        // }
      })}
      {/* <NavItem
        to="https://uuoni-demo.herokuapp.com"
        target="_blank"
        rel="noopener"
      >
        Demo
      </NavItem> */}
      <NavSpace />
      <NavItemOutlined
        to="https://app.uuoni.com/register"
        target="_blank"
        rel="noopener"
      >
        Get Started
      </NavItemOutlined>
      {/* <NavItem to="https://uuoni.herokuapp.com/signup">Sign Up</NavItem> */}
    </>
  )
}

export default NavbarLinks
