import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import FooterAbstractShape from "../../images/artwork/Footer-AbstractShape_v2.svg"
import FB from "../../images/FB_v1.inline.svg"
import IG from "../../images/IG_v1.inline.svg"
import LinkedIn from "../../images/LinkedIn_v1.inline.svg"
import Twitter from "../../images/Twitter_v1.inline.svg"
import * as Icons from "../Icons"
import FooterAccordion from "./FooterAccordion"
import Logo from "./Logo"
import LogoIcon from "./LogoIcon"

const footerLinks = [
  {
    header: "Company",
    links: [
      {
        title: "About Us",
        to: "/about",
      },
      {
        title: "Contact Us",
        to: "/contact",
      },
    ],
  },
  {
    header: "Product",
    links: [
      {
        title: "Features",
        to: "/features",
      },
      // {
      //   title: "For Teams",
      //   to: "/teams",
      // },
      {
        title: "Pricing",
        to: "/pricing",
      },
      // {
      //   title: "Demo",
      //   to: "https://uuoni-demo.herokuapp.com",
      //   isExternal: true,
      // },
    ],
  },
  {
    header: "Legal",
    links: [
      {
        title: "Privacy Policy",
        to: "/privacy",
      },
      {
        title: "Terms of Usage",
        to: "/terms",
      },
    ],
  },
  {
    header: "Resources",
    links: [
      {
        title: "FAQ",
        to: "/faqs",
      },
    ],
  },
]

const Footer = () => {
  return (
    <FooterArea>
      <FooterBrandLogoMobileContainer>
        <LogoIcon />
        <RTTButton
          onClick={() =>
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
          style={{ paddingLeft: 0 }}
        >
          Return to Top
          <Icons.RightArrowIcon
            style={{
              transform: "rotate(270deg)",
            }}
          />
        </RTTButton>
      </FooterBrandLogoMobileContainer>

      <RTTButtonDesktop
        onClick={() =>
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          })
        }
        style={{ paddingLeft: 0 }}
      >
        Return to Top
        <Icons.RightArrowIcon
          style={{
            transform: "rotate(270deg)",
          }}
        />
      </RTTButtonDesktop>

      <FooterBrandContainer>
        <FooterBrandLogoContainer>
          <Logo />
        </FooterBrandLogoContainer>
        <FooterBrandCTAContainer></FooterBrandCTAContainer>

        <FooterNavContainerMobile>
          <FooterNavContainerGrid>
            {footerLinks.map((list, index) => (
              <FooterAccordion
                key={index}
                header={list.header}
                links={list.links}
              />
            ))}
          </FooterNavContainerGrid>
        </FooterNavContainerMobile>

        <FooterNavContainer>
          <FooterNavContainerGrid>
            <FooterNavContainerGridItem>
              <FooterNavContainerGridHeader>
                <h6>Company</h6>
              </FooterNavContainerGridHeader>
              <FooterNavContainerGridLinks>
                <FooterNavContainerGridLinkItem>
                  <FooterNavContainerGridLink title="About Us" to="/about">
                    About Us
                  </FooterNavContainerGridLink>
                </FooterNavContainerGridLinkItem>
                {/* <FooterNavContainerGridLinkItem>
                    <FooterNavContainerGridLink to="/#">
                      Careers
                    </FooterNavContainerGridLink>
                  </FooterNavContainerGridLinkItem> */}
                <FooterNavContainerGridLinkItem>
                  <FooterNavContainerGridLink title="Contact Us" to="/contact">
                    Contact Us
                  </FooterNavContainerGridLink>
                </FooterNavContainerGridLinkItem>
              </FooterNavContainerGridLinks>
            </FooterNavContainerGridItem>
            <FooterNavContainerGridItem>
              <FooterNavContainerGridHeader>
                <h6>Product</h6>
              </FooterNavContainerGridHeader>
              <FooterNavContainerGridLinks>
                <FooterNavContainerGridLinkItem>
                  <FooterNavContainerGridLink title="Features" to="/features">
                    Features
                  </FooterNavContainerGridLink>
                </FooterNavContainerGridLinkItem>
                {/* <FooterNavContainerGridLinkItem>
                  <FooterNavContainerGridLink title="For Teams" to="/teams">
                    For Teams
                  </FooterNavContainerGridLink>
                </FooterNavContainerGridLinkItem> */}
                <FooterNavContainerGridLinkItem>
                  <FooterNavContainerGridLink to="/pricing">
                    Pricing
                  </FooterNavContainerGridLink>
                </FooterNavContainerGridLinkItem>
                {/* <FooterNavContainerGridLinkItem>
                  <FooterNavContainerGridLink
                    to="https://uuoni-demo.herokuapp.com"
                    target="_blank"
                    rel="noopener"
                  >
                    Demo
                  </FooterNavContainerGridLink>
                </FooterNavContainerGridLinkItem> */}
                {/* <FooterNavContainerGridLinkItem>
                    <FooterNavContainerGridLink to="/#">
                      Success Stories
                    </FooterNavContainerGridLink>
                  </FooterNavContainerGridLinkItem> */}
              </FooterNavContainerGridLinks>
            </FooterNavContainerGridItem>
            <FooterNavContainerGridItem>
              <FooterNavContainerGridHeader>
                <h6>Legal</h6>
              </FooterNavContainerGridHeader>
              <FooterNavContainerGridLinks>
                <FooterNavContainerGridLinkItem>
                  <FooterNavContainerGridLink
                    title="Privacy Policy"
                    to="/privacy"
                  >
                    Privacy Policy
                  </FooterNavContainerGridLink>
                </FooterNavContainerGridLinkItem>
                {/* <FooterNavContainerGridLinkItem>
                    <FooterNavContainerGridLink to="/#">
                      Careers
                    </FooterNavContainerGridLink>
                  </FooterNavContainerGridLinkItem> */}
                <FooterNavContainerGridLinkItem>
                  <FooterNavContainerGridLink
                    title="Terms of Usage"
                    to="/terms"
                  >
                    Terms of Usage
                  </FooterNavContainerGridLink>
                </FooterNavContainerGridLinkItem>
              </FooterNavContainerGridLinks>
            </FooterNavContainerGridItem>
            <FooterNavContainerGridItem>
              <FooterNavContainerGridHeader>
                <h6>Resources</h6>
              </FooterNavContainerGridHeader>
              <FooterNavContainerGridLinks>
                <FooterNavContainerGridLinkItem>
                  <FooterNavContainerGridLink title="FAQ" to="/faqs">
                    FAQ
                  </FooterNavContainerGridLink>
                </FooterNavContainerGridLinkItem>
                {/* <FooterNavContainerGridLinkItem>
                    <FooterNavContainerGridLink to="/resources">
                      Resource Library
                    </FooterNavContainerGridLink>
                  </FooterNavContainerGridLinkItem>
                  <FooterNavContainerGridLinkItem>
                    <FooterNavContainerGridLink to="/#">
                      Support Center
                    </FooterNavContainerGridLink>
                  </FooterNavContainerGridLinkItem> */}
              </FooterNavContainerGridLinks>
            </FooterNavContainerGridItem>
          </FooterNavContainerGrid>
        </FooterNavContainer>
      </FooterBrandContainer>

      <FooterContainer>
        <FooterContainerBrand>
          <p className="copyright">
            © {new Date().getFullYear()} Uuoni Pte Ltd, All rights reserved.
          </p>
        </FooterContainerBrand>
        <FooterContainerLinks>
          <FooterContainerLinkItem
            target="_blank"
            to="https://www.facebook.com/Uuoni/"
          >
            <Icons.FBIcon />
          </FooterContainerLinkItem>
          <FooterContainerLinkItem
            target="_blank"
            to="https://www.linkedin.com/company/uuoni/"
          >
            <Icons.LinkedInIcon />
          </FooterContainerLinkItem>
          <FooterContainerLinkItem
            target="_blank"
            to="https://www.instagram.com/uuoni.sg/"
          >
            <Icons.IGIcon />
          </FooterContainerLinkItem>
          <FooterContainerLinkItem
            target="_blank"
            to="https://twitter.com/uuoni_sg"
          >
            <Icons.TwitterIcon />
          </FooterContainerLinkItem>
          {/* <FooterContainerLinkItem to="/cookies">
            Cookie Policy
          </FooterContainerLinkItem> */}
        </FooterContainerLinks>
      </FooterContainer>
    </FooterArea>
  )
}

export default Footer

const FooterBg = styled.div`
  position: relative;
  background-image: url(${FooterAbstractShape});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;

  @media screen and (max-width: 1024px) {
    background-image: none;
  }
`

const FooterArea = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  max-width: 1920px;

  @media screen and (min-width: 768px) and (max-width: 1024px) {

  }

}
`

const FooterBrandContainer = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  order: 2;

  @media screen and (min-width: 1025px) {
    display: grid;
    grid-template-columns: 20% 20% 60%;
    margin-top: 3rem;
    order: 1;
    margin-bottom: 4.25rem;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    flex-direction: row;
  }

  @media screen and (max-width: 767px) {
    margin-top: -1rem;
  }
`

const FooterBrandLogoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1025px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 1024px) {
    align-items: center;
    text-align: center;
    width: 140pt;
    min-width: 140pt;
    margin-right: 5%;
    margin-left: 0%;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const FooterBrandLogoMobileContainer = styled.div`
  display: flex;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const FooterBrandCTAContainer = styled.div`
  display: flex;

  label {
    margin-bottom: 0.5rem;
  }

  @media screen and (min-width: 1025px) {
    margin-top: 0;
  }

  @media screen and (max-width: 1024px) {
    align-self: center;
  }
`

const FooterNavContainer = styled.nav`
  order: 1;

  @media screen and (min-width: 1025px) {
    order: 2;
    margin-top: 0;
    width: 75%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 1.25rem;
    margin-left: auto;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const FooterNavContainerMobile = styled.nav`
  order: 1;
  margin-bottom: 1.25rem;
  margin-top: 3rem;

  @media screen and (min-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 767px) {
  }
`

const FooterNavContainerGrid2 = styled.ul`
  display: grid;
  grid-column-gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
  grid-template-columns: repeat(3, 1fr);
`

const FooterNavContainerGrid = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 1025px) {
    grid-column-gap: 2.5rem;
    grid-row-gap: 2.5rem;
  }

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
`
// grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));

const FooterNavContainerGridItem = styled.li`
  margin: 0;
}
`

const FooterNavContainerGridHeader = styled.div`
  color: var(--theme-ui-colors-grey-90, #232129);
  margin-bottom: 1.5rem;

  @media screen and (min-width: 1025px) {
    border-bottom: 0;
    margin-bottom: 2.5rem;
  }
`

const FooterNavContainerGridLinks = styled.ul`
  display: grid;
  list-style: none;
  margin: 0px;
  padding: 0px;

  li *:last-child {
    margin-bottom: 0;
  }
`

const FooterNavContainerGridLinkItem = styled.li`
  line-height: 1.25;
  margin-bottom: 1rem;

  @media screen and (min-width: 1025px) {
    padding: 0;
    margin-bottom: 2.5rem;
  }
`

const FooterNavContainerGridLink = styled(Link)`
  align-items: center;
  padding: 2px 4px;
  transform: translateX(-4px);
  display: inline-block;
  white-space: nowrap;
  position: relative;
  color: #222222;
  text-decoration: none;
  letter-spacing: 0.25px;

  li *:last-child {
    margin-bottom: 0;
  }

  :before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  :hover {
    text-decoration: none;
    color: #466fa5;
    transition: all 0.5s;
  }
`
const FooterContainer = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: column;
  order: 3;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-top: 1px solid #839dec;

  @media screen and (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }

  @media screen and (min-width: 1025px) {
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  @media screen and (max-width: 767px) {
  }
`

const FooterContainerBrand = styled.div`
  @media screen and (min-width: 1025px) {
    margin-top: 0;
    order: 0;
    order: 2;
  }

  @media screen and (max-width: 767px) {
    margin-top: 1rem;
    order: 2;
  }
`
const FooterContainerLinks = styled.div`
  display: inline-flex;
  margin-bottom: 0.5rem;

  @media screen and (min-width: 1025px) {
    margin-bottom: 0;
  }
`
const FooterContainerLinkItem = styled(Link)`
  align-items: center;
  padding: 2px 4px;
  transform: translateX(-4px);
  display: inline-block;
  white-space: nowrap;
  margin: 0 20px;
  position: relative;
  color: #222222;
  text-decoration: none;
  letter-spacing: 0.25px;

  :not(:last-of-type) {
    margin-right: 0.1rem;
  }
`

const RTTButton = styled.button`
  align-items: center;
  border: var(--theme-ui-colors-grey-60, #635e69);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-end;
  transition: background 0.5s, border 0.5s, color 0.5s;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  color: #1b5998;
  min-height: calc(2rem + 0.25rem);
  min-width: calc(2rem + 0.25rem);
  border-radius: 8px;
  position: relative;
  background: var(--theme-ui-colors-white, #ffffff);
  z-index: 1;
  letter-spacing: 0.25px;
  align-self: flex-end;
  font-weight: 500;

  :hover {
    font-weight: 600;
  }

  :hover #Right-Arrow-Button {
    animation: bounce infinite 3s;
  }

  @media screen and (max-width: 767px) {
    margin-left: auto;
    margin-bottom: auto;
  }

  @media screen and (max-width: 1024px) {
    #Right-Arrow-Button {
      animation: bounce infinite 3s;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateX(0%);
    }
    25% {
      transform: translateX(15%);
    }

    50% {
      transform: translateX(0%);
    }

    75% {
      transform: translateX(15%);
    }
  }
`

const RTTButtonDesktop = styled.button`
  align-items: center;
  border: var(--theme-ui-colors-grey-60, #635e69);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-end;
  transition: background 0.5s, border 0.5s, color 0.5s;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  color: #1b5998;
  min-height: calc(2rem + 0.25rem);
  min-width: calc(2rem + 0.25rem);
  border-radius: 8px;
  position: relative;
  background: var(--theme-ui-colors-white, #ffffff);
  z-index: 1;
  letter-spacing: 0.25px;
  align-self: flex-end;
  font-weight: 500;

  :hover {
    font-weight: 600;
  }

  :hover #Right-Arrow-Button {
    animation: bounce infinite 3s;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    #Right-Arrow-Button {
      animation: bounce infinite 3s;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateX(0%);
    }
    25% {
      transform: translateX(15%);
    }

    50% {
      transform: translateX(0%);
    }

    75% {
      transform: translateX(15%);
    }
  }
`
const FBIcon = styled(FB)`
  width: 2rem;
  height: 2rem;

  :hover {
    #Icon_awesome-facebook-square {
      fill: #1b5998 !important;
      transition: all 0.5s;
    }
  }
`
const LinkedInIcon = styled(LinkedIn)`
  width: 2rem;
  height: 2rem;

  :hover {
    #Icon_awesome-linkedin {
      fill: #1b5998 !important;
      transition: all 0.5s;
    }
  }
`
const IGIcon = styled(IG)`
  width: 2rem;
  height: 2rem;

  :hover {
    #Icon_awesome-instagram {
      fill: #1b5998 !important;
      transition: all 0.5s;
    }
  }
`
const TwitterIcon = styled(Twitter)`
  width: 2rem;
  height: 2rem;

  :hover {
    #Icon_awesome-twitter {
      fill: #1b5998 !important;
      transition: all 0.5s;
    }
  }
`
