import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import Logo from "./Logo"
import NavbarLinks from "./NavbarLinks"

const Navigation = styled.nav`
  height: 11rem;
  display: flex;
  background-color: #fff;
  position: relative;
  justify-content: space-between;
  margin: 0 auto;
  z-index: 2;
  align-self: center;
  max-width: 1920px;

  @media screen and (min-width: 768px) {
    padding: 0 5%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    height: 9rem;
  }

  @media screen and (max-width: 767px) {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    padding: 5% 5%;
    height: unset;
  }
`

// padding: 0 calc(0.05 * 1920px);

const Toggle = styled.div`
  display: none;
  cursor: pointer;
  height: 100%;

  @media screen and (max-width: 767px) {
    display: flex;
  }
`

const NavPanel = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 767px) {
    height: inherit;
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 5vw;
    background-color: #fff;
    transition: all 0.15s ease-in;
    top: 4.5rem;
    right: 0;
    display: ${props => !props.open && "none"};
    opacity: ${props => (props.open ? "1" : "0")};
  }
`
// right: ${props => (props.open ? "0%" : "-100%")};

// display: ${props => (props.open ? "block" : "none")};

const Mask = styled.div`
  display: ${props => (props.open ? "block" : "none")};
  opacity: ${props => (props.open ? "1" : "0")};
  content: "";
  position: absolute;
  top: 50vw;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: #00000094;
  transition: all 0.15s ease-in;
  z-index: -1;

  @media screen and (min-width: 768px) {
    display: none;
  }
`
// background-color: #ffffff96;

const Hamburger = styled.div`
  background-color: #111;
  width: 20px;
  height: 2.5px;
  transition: all 0.15s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 20px;
    height: 2.5px;
    background-color: #111;
    content: "";
    position: absolute;
    transition: all 0.15s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-7px, 0px)" : "rotate(0deg)"};
    top: -7px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 7px;
  }
`
const Navbar = () => {
  const node = useRef()
  const [navbarOpen, setNavbarOpen] = useState(false)

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return
    }
    setNavbarOpen(false)
  }

  useEffect(() => {
    if (navbarOpen) {
      document.addEventListener("mousedown", handleClickOutside)
      document.documentElement.style.overflow = "hidden"
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
      document.documentElement.style.overflow = ""
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.documentElement.style.overflow = ""
    }
  }, [navbarOpen])

  return (
    <Navigation>
      <Logo />
      <div ref={node}>
        <Toggle onClick={() => setNavbarOpen(!navbarOpen)}>
          <Hamburger open={navbarOpen} />
        </Toggle>
        <NavPanel open={navbarOpen}>
          <NavbarLinks />
        </NavPanel>
      </div>
      <Mask open={navbarOpen} />
    </Navigation>
  )
}

export default Navbar
