import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import LogoIcon from "../../images/logo.png"

const LogoWrap = styled.div``

const LogoImg = styled.img`
  width: 43pt;
  height: 58pt;
`

const Logo = () => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "logo-brand-og" }, extension: { eq: "png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, layout: FIXED)
        }
      }
    }
  `)

  // fluid(maxWidth: 175, pngQuality: 100) {
  //   ...GatsbyImageSharpFluid
  // }

  return (
    <LogoWrap as={Link} to="/">
      {/* <GatsbyImage
        image={data.file.childImageSharp.gatsbyImageData}
        alt="logo"
      /> */}
      <LogoImg src={LogoIcon} alt="Logo" style={{ marginBottom: 0 }} />
    </LogoWrap>
  )
}

export default Logo
