import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"

const FooterAccordion = props => {
  const [tabOpen, setTabOpen] = useState(false)

  return (
    <li style={{ marginBottom: "1.25rem" }}>
      <h6 style={{ marginBottom: 0 }}>
        <FooterNavContainerGridHeader onClick={() => setTabOpen(!tabOpen)}>
          {props.header}
          {tabOpen ? <ToggleIcon open /> : <ToggleIcon />}
        </FooterNavContainerGridHeader>
      </h6>
      {tabOpen && (
        <FooterNavContainerGridLinks>
          {props.links.map((link, index) => (
            <FooterNavContainerGridLinkItem key={index}>
              <FooterNavContainerGridLink title={link.title} to={link.to}>
                {link.title}
              </FooterNavContainerGridLink>
            </FooterNavContainerGridLinkItem>
          ))}
        </FooterNavContainerGridLinks>
      )}
    </li>
  )
}

export default FooterAccordion

const ToggleIcon = styled.div`
  background-color: #707070;
  width: 20px;
  height: 2px;
  transition: all 0.15s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};
  border-radius: 30px;

  ::before {
    width: 20px;
    height: 2px;
    background-color: #707070;
    content: "";
    position: absolute;
    transition: all 0.15s linear;
    border-radius: 30px;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-7px, 0px)" : "rotate(-90deg)"};
    top: ${props => (props.open ? "-7px" : "0px")};
  }
`

const FooterNavContainerGridItem = styled.li`
}
`

const FooterNavContainerGridHeader = styled.button`
  all: inherit;
  align-items: center;
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  text-transform: inherit;
  width: 100%;
`

const FooterNavContainerGridLinks = styled.ul`
  display: grid;
  list-style: none;
  margin: 0px;
  padding: 0px;
  margin-top: 0.5rem;

  li *:last-child {
    margin-bottom: 0;
  }
`

const FooterNavContainerGridLinkItem = styled.li`
  @media screen and (min-width: 1025px) {
    padding: 0;
    margin-bottom: 2.5rem;
  }
`

const FooterNavContainerGridLink = styled(Link)`
  width: 100%;
  align-items: center;
  padding: 2px 4px;
  transform: translateX(-4px);
  display: inline-block;
  white-space: nowrap;
  position: relative;
  color: #222222;
  text-decoration: none;

  li *:last-child {
    margin-bottom: 0;
  }

  :before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  :hover {
    text-decoration: none;
    color: #466fa5;
    transition: all 0.5s;
  }
`

const FooterNavContainerGridExtLink = styled.a`
  width: 100%;
  align-items: center;
  padding: 2px 4px;
  transform: translateX(-4px);
  display: inline-block;
  white-space: nowrap;
  position: relative;
  color: #222222;
  text-decoration: none;

  li *:last-child {
    margin-bottom: 0;
  }

  :before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  :hover {
    text-decoration: none;
    color: #466fa5;
    transition: all 0.5s;
  }
`
